<template>
  <v-layout
    column
    nowrap
    style="background: linear-gradient(90deg, rgba(0,0,9,1) 0%, rgba(0,0,38,1) 35%, rgba(0,0,0,1) 100%)"
  >
    <!--    <v-toolbar class="white">-->
    <!--      <v-toolbar-title>Welcome to me</v-toolbar-title>-->
    <!--      <v-spacer></v-spacer>-->
    <!--    </v-toolbar>-->
    <v-layout
      style="height: 100vh; background-size: 100vw 100vh"
      v-bind:style="{ 'background-image': `url(${background})` }"
    >
      <div class="pl-12" style="height: fit-content; margin-top: 7vh">
        <div style="font-size: 5.625rem; font-weight: bold">
          <span class="text-gradient">
            Welcome
          </span>
        </div>
        <div style="font-size: 1.75rem">
          Welcome to Sustainability Monitoring Platform!
        </div>
        <div class="pt-2" style="font-style: italic">
          Bringing you the best services, great experiences with enthusiastic support.
        </div>
        <div style="font-style: italic">
          Join and get ready to enjoy our services.
        </div>
        <v-btn class="mt-8" color="primary" dark href="/pages/login" large rounded>
          Get Started
        </v-btn>
      </div>
    </v-layout>

    <!--      <section>-->
    <!--        <v-parallax :src="background" style="height: 100vh">-->
    <!--          <v-layout column align-center justify-center class="white&#45;&#45;text">-->
    <!--            &lt;!&ndash;            <img :src="require('@/assets/images/landing-page/ld_1.jpg')" alt="Vuetify.js" height="200">&ndash;&gt;-->
    <!--            <h1 class="white&#45;&#45;text mb-2 display-1 text-xs-center">Welcome to me</h1>-->
    <!--            <div class="subheading mb-3 text-xs-center">Powered by MC</div>-->
    <!--            <v-btn class="blue lighten-2 mt-5" dark large href="/projects">-->
    <!--              Get Started-->
    <!--            </v-btn>-->
    <!--          </v-layout>-->
    <!--        </v-parallax>-->
    <!--      </section>-->

    <section>
      <v-container grid-list-xl>
        <v-layout class="my-5" justify-center row wrap>
          <v-flex sm4 xs12>
            <v-card class="elevation-0 transparent">
              <v-card-title class="layout justify-center" primary-title>
                <div class="headline" style="color: #9d9d9d">Company info</div>
              </v-card-title>
              <v-card-text style="color: #9d9d9d">
                Skymap Global is one of Asia’s leading Earth Observation Analytics company with a vision to leverage
                data for everyday use. We bring life to data through innovation by research. By connecting minds across
                borders and cultures, fostering talent and with our advanced knowledge centres, we create products of
                the future.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex sm8 xs12>
            <v-card class="elevation-0 transparent">
              <v-card-title class="layout justify-center" primary-title>
                <div class="headline" style="color: #9d9d9d">Contact us</div>
              </v-card-title>
              <v-card-text style="color: #9d9d9d; font-weight: bold">
                <v-layout class="pl-12" justify-center row wrap>
                  <v-flex sm6 style="justify-items: center" xs12>
                    <h3>PT.Geopantau Citra Solusi</h3>
                    <v-list class="transparent" dense>
                      <v-list-item style="color:#9d9d9d;">
                        <v-list-item-action>
                          <v-icon color="#9d9d9d">mdi-phone</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <div>+62 21 82743316</div>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="color: #9d9d9d">
                        <v-list-item-action>
                          <v-icon color="#9d9d9d">mdi-home</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <div>Rukan Sentra Niaga Galaxy Park RSK 3 no.82 Bekasi City, West Java Indonesia-17147</div>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="color: #9d9d9d">
                        <v-list-item-action>
                          <v-icon color="#9d9d9d">mdi-email</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <div>admin@geopantau.com</div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                  <v-flex sm6 xs12>
                    <h3>SkyMap Global Pte Ltd</h3>
                    <v-list class="transparent" dense>
                      <v-list-item style="color:#9d9d9d;">
                        <v-list-item-action>
                          <v-icon color="#9d9d9d">mdi-phone</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <div>+65 6286 0018</div>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="color: #9d9d9d">
                        <v-list-item-action>
                          <v-icon color="#9d9d9d">mdi-home</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <div>
                            18 Howard Road #07-02 Novelty Bizcenter, Singapore 369585
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="color: #9d9d9d">
                        <v-list-item-action>
                          <v-icon color="#9d9d9d">mdi-email</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <div>sales@skymapglobal.com</div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-layout></v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </section>

    <!--      <v-footer class="blue darken-2">-->
    <!--        <v-layout row wrap align-center>-->
    <!--          <v-flex xs12>-->
    <!--            <div class="white&#45;&#45;text ml-3">-->
    <!--              Made with-->
    <!--              <a class="black&#45;&#45;text" href="https://vuetifyjs.com" target="_blank">Vuetify</a>-->
    <!--            </div>-->
    <!--          </v-flex>-->
    <!--        </v-layout>-->
    <!--      </v-footer>-->
  </v-layout>
</template>

<script>
export default {
  name: 'LandingPage',
  components: {},
  data() {
    return {
      title: 'Your Logo',
      background: require('@/assets/images/landing-page/landingpage-bg.jpg'),
      sectionImg: require('@/assets/images/landing-page/section.jpg'),
    }
  },
  created() {
    let cacheRoute = localStorage.getItem('cacheRoute')
    if(cacheRoute) this.$router.push(cacheRoute)
  },
  methods: {},
}
</script>
<style></style>
